export default () => ({
  current: 0,
  hacds: "",
  search: "",
  minPrice: "",
  maxPrice: "",
  shapes: "",
  styles: "",
  nameliteral: "",
  numberliteral: "",
  color: "",
});
