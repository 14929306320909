import { indexBtnsOptions, priceOptions } from "@/utils/options";
import getList from "@/actions/getList";
import { queryDiamondbyCondition, queryCollectDiamonds } from "@/services";
import search from "../common/search";
import { getAddress } from "@/utils/handle";
const urlMap = {
  all: queryDiamondbyCondition, // “全部商品”的请求方法
  hot: queryCollectDiamonds, // “热门收藏”的请求方法
};
const handleParams = (search, state) => {
  const params = { ...search, order: state.order };
  params.status = "2";
  if (search.search) params.status = "";
  if (state.indexActBtn == indexBtnsOptions[1].value) {
    params.address = getAddress();
    params.status = "";
  }
  return params;
};
export default {
  namespaced: true,
  state: {
    indexActBtn: indexBtnsOptions[0].value,
    order: priceOptions[0].value,
    list: [],
    search: search(),
    actDiamond: null,
    hasMore: true,
  },
  mutations: {
    setState(state, payload) {
      for (let key in payload) {
        state[key] = payload[key];
      }
    },
  },
  actions: {
    nextPage({ state, commit }) {
      const { search, indexActBtn, list } = state;
      search.current++;
      commit("setState", { search });
      getList(handleParams(search, state), urlMap[indexActBtn]).then((data) => {
        if (!data.length) commit("setState", { hasMore: false });
        commit("setState", { list: list.concat(data) });
      });
    },
    firstPage({ state, commit }) {
      const { search, indexActBtn } = state;
      search.current = 1;
      commit("setState", { search });
      getList(handleParams(search, state), urlMap[indexActBtn]).then((data) =>
        commit("setState", { list: data })
      );
    },
  },
};
