import request from "@/utils/request";
const baseUrl = "/hacds";
import { getAddress } from "@/utils/handle";
// 配置接口
export const queryDiamondRules = (data) =>
  request(`${baseUrl}/diamond/queryDiamondRules`, {
    method: "POST",
    data,
  });
// 钻石状态操作接口
export const updateDiamondPriceAndStatusAndMessage = (data) => {
  return request(`${baseUrl}/diamond/updateDiamondPriceAndStatusAndMessage`, {
    method: "POST",
    data,
    headers: {
      hasAddress: true,
    },
  });
};
// 下架
export const soldOut = (data) => {
  return request(`${baseUrl}/diamond/soldOut`, {
    method: "POST",
    data,
    headers: {
      hasAddress: true,
    },
  });
};
// 钻石合并接口
export const dealGroupName = (data) =>
  request(`${baseUrl}/diamond/dealGroupName`, {
    method: "POST",
    data,
    headers: {
      hasAddress: true,
    },
  });
// 钻石解除合并
export const dismantleDiamond = (data) =>
  request(`${baseUrl}/diamond/dismantleDiamond`, {
    method: "POST",
    data,
    headers: {
      hasAddress: true,
    },
  });
// 根据钻石名字获取钻石
export const queryDiamondByName = (data) =>
  request(`${baseUrl}/diamond/queryDiamondByName`, {
    method: "POST",
    data,
  });

// 获取所有hacds数量
export const queryDiamondsHacds = (data) => {
  return request(`${baseUrl}/diamond/queryDiamondsHacds`, {
    method: "POST",
  });
};
// 获取钻石接口
export const queryDiamondbyCondition = (data) => {
  if (data.shapes) data.shapes = data.shapes.split("|")[0];
  const config = {
    method: "POST",
    data,
    headers: {},
  };
  if (data?.address) {
    config.headers.hasAddress = true;
  }
  return request(`${baseUrl}/diamond/queryDiamondbyCondition`, config);
};
// 收藏接口
export const optDiamondCollect = (data) =>
  request(`${baseUrl}/diamond/optDiamondCollect`, {
    method: "POST",
    data,
    headers: {
      hasAddress: true,
    },
  });
// 是否是收藏状态
export const booleanCollect = (data) =>
  request(`${baseUrl}/diamond/booleanCollect`, {
    method: "POST",
    data,
    headers: {
      hasAddress: true,
    },
  });
// 获取用户收藏钻石信息
export const queryCollectDiamonds = (data) =>
  request(`${baseUrl}/diamond/queryCollectDiamonds`, {
    method: "POST",
    data,
    headers: {
      hasAddress: true,
    },
  });

// 购买钻石调龙哥接口
export const tsig = (data) =>
  request(`${baseUrl}/diamond/tsig`, {
    method: "POST",
    data,
    headers: {
      hasAddress: true,
    },
  });
// 邮箱相关
export const insertEmail = (data) =>
  request(`${baseUrl}/diamond/insertEmail`, {
    method: "POST",
    data,
    headers: {
      hasAddress: true,
    },
  });

export const updateEmail = (data) =>
  request(`${baseUrl}/diamond/updateEmail`, {
    method: "POST",
    data,
    headers: {
      hasAddress: true,
    },
  });

export const queryEmail = (data) =>
  request(`${baseUrl}/diamond/queryEmail`, {
    method: "POST",
    data,
    headers: {
      hasAddress: true,
    },
  });
// 订单
// diamonds(单独或者组合)
// buyer(买家地址)
// offer（就是钻石基础表的price）（标的钻石价格，和手续费没关系）
// txbody
// timer(时间戳)
// seller（卖方地址）
// sellEmail（卖房email）
// status(0失效，1进行中，2已完成)
// fee_charge手续费;
export const insertOrder = (data) =>
  request(`${baseUrl}/diamond/insertOrder`, {
    method: "POST",
    data,
    headers: {
      hasAddress: true,
    },
  });
export const updateOrder = (data) =>
  request(`${baseUrl}/diamond/updateOrderStatus`, {
    method: "POST",
    data,
    headers: {
      hasAddress: true,
    },
  });
export const queryOrder = (data) =>
  request(`${baseUrl}/diamond/queryOrder`, {
    method: "POST",
    data,
    headers: {
      hasAddress: true,
    },
  });

// 查询用户余额
// address_list [string]逗号隔开的账户地址列表;最多200个批量查询
// unitmei [bool] 是否以“枚”为单位返回浮点数字符串;否则返回さ标准单位
// kind [menu 查询返回的种类; kind=h 只返回HAC余额， kind=hs 返回HAC、BTC余额，不传或传递 hsd 则返回全部类型余额
export const queryAddressHacds = () =>
  request(`${baseUrl}/diamond/queryAddressHacds`, {
    method: "POST",
    data: { address: getAddress() },
  });
// txbody的submit，二次签名时候来调用
export const txbodySubmit = (txbody) =>
  request(`${baseUrl}/diamond/txbodySubmit`, {
    method: "POST",
    data: { txbody },
  });
