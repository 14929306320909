import {
  queryDiamondByName,
  dismantleDiamond,
  updateDiamondPriceAndStatusAndMessage,
  queryEmail,
  tsig, // 购买
  insertOrder,
  updateOrder,
  txbodySubmit,
  soldOut,
} from "@/services";
import { Message } from "element-ui";
import { signtx, transfer } from "@/services/moneyNex";
import { intervalTime, fee_ratio, handleFeeHac } from "@/utils/handle";
import store from "@/store";
import moment from "moment";
import { IS_TEST, HACDS_UNIT, Receiving_address, MIN_PRICE } from "@/keys";
import { getAddress } from "@/utils/handle";
import i18n from "@/public/i18n";
export class Diamond {
  address = "";
  approx_fee_offer = "";
  average_burn_price = "";
  block_height = "";
  color = "";
  colorClassify = "";
  current_block_hash = "";
  custom_message = "";
  groupName = "";
  inscriptions = "";
  letterFeature = "";
  life_gene = "";
  miner_address = "";
  name = "";
  nonce = "";
  number = "";
  prev_block_hash = "";
  _price = "";
  set price(value) {
    try {
      if (!value) return (this._price = `${MIN_PRICE}`);
      value = `${value}`;
      // 移除所有非数字和小数点的字符
      let numericValue = value.replace(/[^0-9.]/g, "");
      // 确保小数点只出现一次
      const onlyOneDot = numericValue.split(".").length <= 2;
      if (!onlyOneDot) {
        // 如果小数点超过一个，移除多余的小数点
        const parts = numericValue.split(".");
        numericValue = parts[0] + "." + parts[1];
      }
      // 将字符串转换为数字
      const numberValue = parseFloat(numericValue);
      // 确保输入的最小值为1
      if (numberValue < MIN_PRICE) {
        numericValue = `${MIN_PRICE}`;
      }
      this._price = numericValue;
    } catch (err) {
      console.log(err);
    }
  }
  get price() {
    return this._price;
  }
  shape = "";
  status = "1";
  visual_gene = "";
  score = 1;
  // 个性化
  checked = false;
  disabled = false;
  list = [];
  _name = "";
  isLike = "0";
  // 0已经收藏，1未收藏
  // 上面的好像反了，0是未收藏，1是已收藏
  listingChecked = false;
  hacdsCount = 1;
  constructor(params = {}) {
    for (let key in params) {
      if (params[key]) this[key] = params[key];
    }
    if (this.status?.length > 1) {
      this.status = this.status.substring(0, 1);
    }
    if (this.status == 0) {
      this.checked = true;
      this.disabled = true;
    } else if (this.status == 2) {
      this.checked = true;
      this.disabled = true;
    }
    if (this.name.length > 6) this.getChildrenList();
    this._name = this.name;
    const length = this.inscriptions?.split("@#$").length;
    if (length) this.hacdsCount = length;
  }
  async getChildrenList() {
    this._name = "组合";
    const res = await queryDiamondByName({ names: this.name });
    if (res?.resultCode == 0 && res?.data?.length) {
      this.list = res.data.map((a) => new Diamond(a));
      this.score = 0;
      this.list.forEach((item) => {
        this.score += item.score;
      });
      if (this.score == 0) this.score = 1;
    }
  }
  // 解除合并
  dismantle() {
    return dismantleDiamond({
      groupName: this.name.replaceAll(",", "|"),
    });
  }
  // 更新状态
  async changeStatus(status) {
    if (status == "2" && !IS_TEST) await transfer([this.price]);
    return updateDiamondPriceAndStatusAndMessage({
      name: this.name,
      status,
    }).then(() => {
      this.status = status;
    });
  }
  async soldOut() {
    return soldOut({
      name: this.name,
      status: "1",
      price: handleFeeHac(this.price).priceInt,
    }).then(() => {
      this.status = "1";
    });
  }
  // 购买
  async buy(offer) {
    const params = {
      action: "dexbuycreate",
      diamonds: this.name,
      buyer: store.getters.userAddress,
      // offer是后来加的求购报价
      offer: offer ? `${offer}` : `${this.price}`,
      fee_address: Receiving_address,
      fee_ratio: `${fee_ratio(this.price).priceInt}`,
      tx_fee: "1:245",
    };
    const res = await tsig(params);
    if (!res?.tx_body) return Message.error("error");
    const txbody = await signtx(res.tx_body);
    const emailObj = await queryEmail({ address: this.address });
    if (emailObj?.resultCode != 0 || !emailObj?.data?.email) return;
    const params2 = {
      ...params,
      seller: this.address,
      status: 1,
      timer: moment().valueOf(),
      sellEmail: emailObj.data.email,
      txbody,
      fee_charge: params.fee_ratio,
      order_type: 0,
      email_content: `you list ${this.score}${HACDS_UNIT} hacds （${this.name}）Transaction has been made, please go to hacds.com to ship in a timely manner. \n\n 您有 ${this.score} hacds（${this.name}）已经成交，请及时前往hacds.com发货。`,
    };
    const res2 = await insertOrder(params2);
    if (res2?.resultCode == 0) {
      this.changeStatus("3");
      Message.success({
        message: i18n.t("message.buySuccess"),
        showClose: true,
      });
    }
  }
}

export class OrderClass extends Diamond {
  timeFormat = "";
  // 计时器
  timer = null;
  // 展示差值
  surplusTimer = "";
  // 是否超时打标
  isOverTime = true;
  // 订单状态
  orderStatus = 0;
  // 打标
  // 分买卖
  // 订单状态 orderStatus 0失效、1进行中、2已完成
  // 订单分类 order_type 0正常、1求购

  // 是否是买卖
  isSell = false;
  constructor(params = {}) {
    super(params);
    this.orderStatus = params.status;
    if (!this.visual_gene && !this.list.length) {
      this.init();
    }
  }
  async init() {
    const address = getAddress();
    if (this.seller == address) this.isSell = true;
    this.time = +this.time;
    if (`${this.time}`.length < 11) this.time = this.time * 1000;
    this.timeFormat = moment(this.time).format("YYYY-MM-DD HH:mm:ss");
    this.handleTime();
    const res = await queryDiamondByName({ names: this.diamonds });
    this.name = this._name = this.diamonds;
    if (res?.resultCode == 0 && res?.data?.length) {
      const [data] = res.data;
      for (let key in data) {
        this[key] = data[key];
      }
    }
  }
  handleTime() {
    // 天数差值
    const date = new Date();
    const time = date.valueOf();
    const diff = moment(this.time).add(2, "days").valueOf();
    if (time > diff) {
      this.isOverTime = true;
      this.surplusTimer = i18n.t("message.ygq");
      if (this.timer) clearTimeout(this.timer);
    } else {
      this.isOverTime = false;
      this.surplusTimer = `${i18n.t("message.jlgqhs")}${intervalTime(
        time,
        diff,
        "text"
      )}`;
    }
    this.timer = setTimeout(() => {
      this.handleTime();
    }, 1000);
  }
  async commit(type = 1) {
    // type 1 同意 ， 2 拒绝
    const params = { ...this };
    if (type == 1) {
      const txbody = await signtx(this.txbody);
      const res = await txbodySubmit(txbody);
      if (res.resultCode == 0) params.status = 2;
      else params.status = 0;
      params.txbody = txbody;
    } else params.status = 0;
    await updateOrder(params);
  }
  async sellCommit() {
    const txbody = await signtx(this.txbody);
    const params = { ...this, status: 2 };
    const res = await txbodySubmit(txbody);
    if (res.resultCode == 0) {
      params.status = 2;
      this.changeStatus("0");
    }
    // else {
    //   params.status = 0;
    //   this.changeStatus("1");
    // }
    await updateOrder(params);
  }
}
