import { menuOptions } from "@/utils/options";
import { queryDiamondRules } from "@/services";
import { handleAttrList } from "@/utils/handle";
import { encrypt, decrypt } from "@/utils/crypto";
import { inEmail, upEmail } from "@/actions/email";
export default {
  namespaced: true,
  state: {
    actPath: menuOptions[1].path,
    attr: { List: [], isLoad: false },
    loading: false,
    _userAddress: window.sessionStorage.getItem("userAddress"),
    app: false,
    set userAddress(val) {
      window.sessionStorage.setItem("userAddress", val);
      if (!val) this.email = "";
      this._userAddress = val;
    },
    get userAddress() {
      return this._userAddress;
    },
    _email: window.sessionStorage.getItem("email"),
    set email(val) {
      window.sessionStorage.setItem("email", val);
      this._email = val;
    },
    get email() {
      return this._email;
    },
    styleType: window.localStorage.getItem("styleType")
      ? window.localStorage.getItem("styleType")
      : "red",
  },
  getters: {},
  mutations: {
    setState(state, payload) {
      for (let key in payload) {
        state[key] = payload[key];
      }
    },
  },
  actions: {
    async getAttrList({ rootState, state, commit }) {
      if (state.attr.isLoad) return;
      const res = await queryDiamondRules({});
      const list = [];
      if (res?.resultCode == 0 && res?.data?.length)
        list.push(...handleAttrList(res.data));
      commit("setState", {
        attr: {
          list,
          isLoad: true,
        },
      });
    },
    commitEmail({ rootState, state, commit }, payload) {
      const { params, isInsertEmail } = payload;
      if (isInsertEmail) inEmail(params);
      else upEmail(params);
    },
  },
};
