import { indexBtnsOptions, priceOptions } from "@/utils/options";
import getList from "@/actions/getList";
import search from "../common/search";
export default {
  namespaced: true,
  state: {
    indexActBtn: indexBtnsOptions[0].value,
    order: priceOptions[0].value,
    list: [],
    search: search(),
  },
  getters: {},
  mutations: {
    setState(state, payload) {
      for (let key in payload) {
        state[key] = payload[key];
      }
    },
  },
  actions: {
    getList({ state, commit }) {
      getList({ commit, params: state.search });
    },
  },
};
