import { Message } from "element-ui";
import store from "@/store";
import { getEmail } from "@/actions/email";
import $bus from "@/public/$bus";
import { IS_TEST, Receiving_address } from "@/keys";
import { handleFeeHac, handleHac } from "@/utils/handle";
// 登录
export const connect = () => {
  return new Promise((resolve) => {
    window.MoneyNex.connect({}, async (acc) => {
      if (acc?.address) {
        Message.success({ message: "success login", showClose: true });
        store.commit("system/setState", { userAddress: acc.address });
        resolve(true);
        const res = IS_TEST
          ? await getEmail({
              address: "1HH78WhPZKV4W6BcCVvFFi9wZZtQsQPz7A",
            })
          : await getEmail({ address: acc.address });
        if (res?.resultCode == 0 && !res?.data?.email) $bus.$emit("email-open");
      } else {
        resolve(false);
        Message.error("fail login");
      }
    });
  });
};
// 签名
export const signtx = (txbody) => {
  return new Promise((resolve) => {
    window.MoneyNex.signtx({ txbody }, (a, b) => {
      resolve(a.body);
    });
  });
};
// 转账
export const transfer = (allPriceList) => {
  let allPrice = 0;
  allPriceList.forEach((price) => (allPrice += handleFeeHac(price).priceInt));
  const { priceHac } = handleHac(allPrice);
  return new Promise((resolve) => {
    let txobj = JSON.stringify({
      actions: [
        {
          kind: 1,
          to: Receiving_address,
          amount: priceHac,
        },
      ],
    });
    txobj = encodeURIComponent(btoa(txobj));
    window.MoneyNex.transfer({ txobj }, (a, b) => {
      if (a.success == true) {
        resolve(true);
      }
    });
  });
};
