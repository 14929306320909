export default {
  app: {
    title: {
      searchTitle: "搜索",
      minHac: "最小HAC",
      maxHac: "最大HAC",
    },
  },
  header: {
    home: "首页",
    tradingPlatform: "Hacds Trade",
    sellOrder: "卖单",
    sell: "出售",
    stash: "仓库",
    mine: "我的",
    search: "搜索",
    min: "最小价格",
    max: "最大价格",
  },
  title: {
    inserEmail: "添加邮箱",
    updateEmail: "更新邮箱",
    myOrders: "我的订单",
    waitOrders: "待确定",
    successOrders: "已完成",
    errorOrders: "已失效",
    dd: "天",
    hh: "时",
    mm: "分",
    ss: "秒",
    wsj: "未上架",
  },
  /*内容部分 二级标题 tab*/
  subheading: {
    allproducts: "全部商品",
    popularCollection: "热门收藏",
    price: "价格",
    filter: "筛选",
    selfSale: "自售",
    offerForPurchase: "求购报价",
    askMeForIt: "向我求购",
    allgoods: "全部物品",
  },
  buy: {
    buyNow: "立即购买",
    collected: "已收藏",
    collect: "收藏",
    sold: "已售出",
    listed: "已上架",
    list: "上架",
    unlist: "下架",
    all: "全选",
    cancel: "取消",
    price: "价格",
    buyIn: "买入",
    sellOut: "卖出",
    needPrice: "求购报价",
    buyMe: "向我求购",
    qy: "签约",
    hb: "合并",
  },
  placeholder: {
    pleaseEnterContent: "请输入内容",
    pleaseSelect: "请选择",
    noData: "暂无数据",
    pleaseEnterContentPrice: "请输入价格",
    addDescription: "添加描述",
    pleaseEnterPricing: "请输入定价",
    pleaseEnterNeedPricing: "请输入求购价格",
    email: "请输入邮箱",
  },
  error: {
    email: "请输入正确邮箱",
    needBuy: "求购不可小于1个hac",
  },
  Language: {
    Language: "换肤",
    Chinese: "中文",
    English: "English",
  },
  message: {
    unmergeSuccessful: "解除合并成功！",
    needEnterEmail: "需要输入绑定邮箱！",
    notFindNex: "请安装MoneyNex插件！",
    buySuccess: "购买成功！",
    needGX: "请勾选需要上架的钻石！",
    sjcg: "上架成功",
    buyError: "您没有足够的hac",
    ygq: "已过期",
    jlgqhs: "距离过期还剩",
    bfsjsb: "部分上架失败",
    qjcsrx: "请检查输入项",
    xjts: "下架将会扣除手续费的10%，是否继续 ?",
    xjts1: "下架提示",
    czcg: "操作成功",
    czsb: "操作失败",
    thisIsMy: "这是我的钻石",
  },
  btn: {
    ok: "确定",
    cancel: "取消",
    reset: "重置",
    unmerge: "解除合并",
    neetBuy: "求购",
    sign: "签署",
    nextY: "下一页",
    lastY: "上一页",
    delivery: "发货",
    refuse: "拒绝",
  },
  table: {
    Invalid: "失效",
    In_progress: "进行中",
    Completed: "已完成",
    // header
    diamondName: "钻石名称",
    date: "日期",
    buyer: "购买者",
    seller: "售卖者",
    status: "订单状态",
    prompt: "提示",
    more: "更多",
    // hacds
    classify: "分类",
    proportion: "比例",
    quanShenSeXi: "全深色系",
    qianSanZiMuChongFu: "前三字母重复",
    sanZhongZiMu: "三种字母",
    erZhongZiMu: "两种字母",
    sanLianZiMu: "三连字母",
    siLianZiMu: "4连字母",
    wuLianZiMu: "5连字母",
    liuZiMu: "6字母",
    shiLIuSe: "16色",
    shiwuSe: "15色",
    zmdc: "字母对称",
    gwbh: "个位编号",
    quanqiansexi: "全浅色系",
    bianhaoduichen: "编号对称",
    bianhaoshunzi: "编号顺子",
    asdgadsf: "编号7连",
    sdfasrgf: "编号6连",
    sdfasrgfsdfg: "编号5连",
    sdfasrgfsdfggf: "编号4连",
    sdfasrgfsdfggfa: "编号数字一样",
    sdfasrgfsdfggfag: "14色",
    sdfasrgfsdfggfagg: "7色",
    sdfasrgfsdfggfaggg: "左三连",
    sdfasrgfsdfggfagggsdf: "纯钻",
    sdfasrgfsdfggfagggsdfgdas: "六边形",
    ab: "菱形",
    abc: "圆形",
    abcf: "水滴",
    abcftge: "三角",
    abcftgezc: "爱心",
    abcftgezcgte: "椭圆",
    abcftgezcgtewe: "左三混合",
    abcftgezcgteweweg: "右三连",
    mnnb: "6色",
    mnnba: "5色",
    mnnbav: "4色",
    mnnbavq: "两边同色",
    mnnbavq1: "中间两块同色",
    mnnbavq1r: "双色混合",
    mnnbavq1rx: "两边各一半",
    mnnbavq1rxrr: "轴对称",
    mnnbavq1rxrr2q: "右三混合",
    mnnbavq1rxrr2q3: "方形",
    ett: "W Dark blue（深蓝色）",
    ettert: "Z Gold黄金色",
    yyy: "S Green绿色",
    thwq: "B Cyan青色",
    ggrqdx: "K Pink cyan渐变青",
    ggrqdxtrt: "X Grey灰粉",
    ggrqdxtrtte: "H Pink粉色",
    ggrqdxtrtteq: "A Red cyan渐变红",
    ggrqdxtrtteqwe: "I Red红色",
    ggrqdxtrtteqweef: "U Blue purple蓝紫",
    ggrtcc: "Y Red purple红紫",
    ggrtcctw: "T Blue蓝色",
    ggrtcctw2: "N Yellow Cyan黄青渐变",
    ggrtcctw23: "E Secret灰色",
    ggrtcctw234: "M Yellow secret（淡黄）",
    ggrtcctw2344: "V Light pink（淡粉）",
    ggrtcctw2344t: "ABBCDD类型、AABCCD类型",
  },
  home: {
    a: " · 我们的现状",
    b: "hacds：基于HACD HIP-5 标准的HACD碎片化协议",
    c: "Hacash一层网络资产HACD作为全球第一个POW NFT，其HIP-5可视化提案标准被社区广泛共识，在HIP-5可视化标准下会产生许多特殊属性HACD。",
    d: "HACD HIP-5标准的必要性",
    e: "追求美的艺术贯穿整个人类历史，多种多样的HACD类型可以满足不同人对不同美的追求，这也正是HACDHIP-5标准的必要性。",
    f: "有需求就有市场",
    g: "有需求就有市场，然而目前并没有一个足够安全并高效的交易市场满足上述交易需求。",
    h: "增强HACD的流通性",
    i: "针对该需求，提出一种在Hacash一层网络通过铭刻及索引hacds铭文对HACD进行同质化分割，以期可增强HACD的流通性的方法。",
    j: "hacds由来",
    k: "碳原子散乱分布只是普通的石墨，但如果能有序排列便可形成星光璀璨的钻石钻石是由上千亿亿个正四面体品胞组成的单品体。将Hacash HIP-5标准的HACD碎片化,称之为钻石晶体，",
    l: "即Hacash Diamond Crystals,缩写为hacds.",
    m: "hacash官方：",
    n: "TG社区：",
    o: "hacdcrystals(@Nakahacds)：",
    p: "discord：",
    q: "了解更多",
    r: "白皮书",
    s: "开发工具",
    t: "hacds",
    u: "钻石信息",
    v: "数量",
    w: "获取 MoneyNex",
    x: "Hacds 打铭文",
  },
};
