var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.show,"width":"70%","show-close":false,"destroy-on-close":"","before-close":_vm.close},on:{"update:visible":function($event){_vm.show=$event}}},[_c('el-form',{ref:"emailRef",attrs:{"model":_vm.form}},[_c('el-form-item',{attrs:{"prop":"diffEmail","rules":[
        { required: true, message: _vm.$t('placeholder.email'), trigger: 'blur' },
        {
          type: 'email',
          message: _vm.$t('error.email'),
          trigger: ['blur', 'change'],
        },
      ]}},[_c('el-input',{attrs:{"type":"email"},model:{value:(_vm.form.diffEmail),callback:function ($$v) {_vm.$set(_vm.form, "diffEmail", $$v)},expression:"form.diffEmail"}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.commit}},[_vm._v(_vm._s(_vm.$t("btn.ok")))]),_c('el-button',{on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("btn.cancel")))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }