export default {
  app: {
    title: {
      searchTitle: "Search",
      minHac: "Min HAC",
      maxHac: "Max HAC",
    },
  },
  header: {
    home: "Home",
    tradingPlatform: "Hacds Trade",
    sellOrder: "Sell order",
    sell: "Sell",
    stash: "Stash",
    mine: "Mine",
    search: "Search",
    min: "min price",
    max: "max price",
  },
  title: {
    inserEmail: "Add Email",
    updateEmail: "Update Email",
    myOrders: "My orders",
    waitOrders: "Await orders",
    successOrders: "Success orders",
    errorOrders: "Overdue orders",
    dd: "days",
    hh: "hours",
    mm: "minutes",
    ss: "seconds",
    wsj: "Not list",
  },
  /*内容部分 二级标题 tab*/
  subheading: {
    allproducts: "All goods",
    popularCollection: "Popular collection",
    price: "Price",
    filter: "filter",
    selfSale: "Self sale",
    offerForPurchase: "Offer for purchase",
    askMeForIt: "Ask me for it",
    allgoods: "All goods",
  },
  buy: {
    buyNow: "Buy now",
    collected: "Collected",
    collect: "collect",
    sold: "sold",
    listed: "Listed",
    list: "List",
    unlist: "Unlist",
    all: "All",
    cancel: "Cancel",
    price: "price",
    buyIn: "Buy in",
    sellOut: "Sell out",
    needPrice: "Need buy price",
    buyMe: "Need buy from me",
    qy: "Signing a contract",
    hb: "Merge",
  },
  placeholder: {
    pleaseEnterContent: "Please enter content",
    pleaseSelect: "Please select",
    noData: "No data",
    pleaseEnterContentPrice: "Please enter content price",
    addDescription: "Add description",
    pleaseEnterPricing: "Please enter pricing",
    pleaseEnterNeedPricing: "Please enter pricing",
    email: "Please enter email",
  },
  error: {
    email: "Please enter right email",
    needBuy: "Must be greater than 1 hac",
  },
  Language: {
    Language: "Language",
    Chinese: "中文",
    English: "English",
  },
  message: {
    unmergeSuccessful: "Unmerge successful!",
    needEnterEmail: "Neet enter your email address!",
    notFindNex: "Please install the MoneyNex plugin!",
    buySuccess: "Buy successful！",
    needGX: "Please choose diamond",
    sjcg: "Successfully listed",
    buyError: "You don't have enough hac",
    ygq: "Expired",
    jlgqhs: "Remaining until expiration",
    bfsjsb: "Partial listing failure",
    qjcsrx: "Please check the input items",
    xjts: "Demolition will deduct 10% of the handling fee. Do you want to continue ?",
    xjts1: "Demolition prompt",
    czcg: "Success",
    czsb: "Fail",
    thisIsMy: "This is Mine",
  },
  btn: {
    ok: "OK",
    cancel: "Cancel",
    reset: "Reset",
    unmerge: "Unmerge",
    neetBuy: "NeetBuy",
    sign: "Sign",
    nextY: "Next",
    lastY: "Last",
    delivery: "delivery",
    refuse: "refuse",
  },
  table: {
    Invalid: "invalid",
    In_progress: "in progress",
    Completed: "completed",
    diamondName: "diamond name",
    date: "Date",
    buyer: "buyer",
    seller: "seller",
    status: "order status",
    prompt: "prompt",
    more: "more",
    // hacds
    classify: "classify",
    proportion: "proportion",
    quanShenSeXi: "All dark colors",
    qianSanZiMuChongFu: "first three letters repeated",
    sanZhongZiMu: "total three letters",
    erZhongZiMu: "total two letters",
    sanLianZiMu: "three letters repeated",
    siLianZiMu: "four letters repeated",
    wuLianZiMu: "five letters repeated",
    liuZiMu: "six letters repeated",
    zmdc: "Symmetric letters",
    gwbh: "Single num",
    quanqiansexi: "All light colors",
    bianhaoduichen: "Symmetric num",
    bianhaoshunzi: "number ing sequence",
    asdgadsf: "seven repeated",
    sdfasrgf: "six repeated",
    sdfasrgfsdfg: "five repeated",
    sdfasrgfsdfggf: "four repeated",
    sdfasrgfsdfggfa: "numbering all repeat",
    sdfasrgfsdfggfag: "All 14 color",
    sdfasrgfsdfggfagg: "Sum seven color",
    mnnb: "Sum six color",
    mnnba: "Sum five color",
    mnnbav: "Sum four color",
    shiLIuSe: "All 16 color",
    shiwuSe: "All 15 color",
    sdfasrgfsdfggfaggg: "Left three pure",
    sdfasrgfsdfggfagggsdf: "Pure",
    sdfasrgfsdfggfagggsdfgdas: "Hexagon",
    ab: "Rhombus",
    abc: "Circle",
    abcf: "Teardrop",
    abcftge: "Triangle",
    abcftgezc: "Heart",
    abcftgezcgte: "Ellipse",
    abcftgezcgtewe: "Left mix pure",
    abcftgezcgteweweg: "Right three pure",
    mnnbavq: "Edge color",
    mnnbavq1: "Center color",
    mnnbavq1r: "Double mix",
    mnnbavq1rx: "Half divide",
    mnnbavq1rxrr: "Symmetry",
    mnnbavq1rxrr2q: "Right mix pure",
    mnnbavq1rxrr2q3: "Square",
    ett: "W Dark blue",
    ettert: "Z Gold",
    yyy: "S Green",
    thwq: "B Cyan",
    ggrqdx: "K Pink cyan",
    ggrqdxtrt: "X Grey",
    ggrqdxtrtte: "H Pink",
    ggrqdxtrtteq: "I Red",
    ggrqdxtrtteqwe: "I Red",
    ggrqdxtrtteqweef: "U Blue purple",
    ggrtcc: "Y Red purple",
    ggrtcctw: "T Blue",
    ggrtcctw2: "N Yellow Cyan",
    ggrtcctw23: "E Secret",
    ggrtcctw234: "M Yellow secret",
    ggrtcctw2344: "V Light pink",
    ggrtcctw2344t: "Classify：ABBCDD、AABCCD",
  },
  home: {
    a: "Our current situation",
    b: "hacds: The Agreement of HACD Crystallization Based on the HACD HIP-5 Standard",
    c: "As the first POW NFT in the world, the HIP-5 visualization proposal standard of Hacash network asset HACD is widely agreed by the community.",
    d: "The necessity of HACD HIP-5 standard",
    e: "The art of pursuing beauty runs through the whole human history. Various HACD types can satisfy different people's pursuit of different beauty, which is the necessity of HACD HIP-5 standard.",
    f: "There is a market for demand",
    g: "There is a market for demand, but there is no safe and efficient enough trading market to meet these trading needs.",
    h: "To enhance the circulation of HACD",
    i: "To meet this requirement, a homogeneous segmentation method is proposed to enhance the circulation of HACD by engraving and indexing hacds inscriptions on the Hacash network.",
    j: "Origin of hacds",
    k: "The scattered distribution of carbon atoms generating just ordinary graphite, but if the carbon atoms are arranged in an orderly manner, they can be formed to be a starlight and brilliant diamond. and a diamond is a single crystal composed of hundreds of billions of tetrahedral cells. After the Hacash HIP-5 standard HACD is fragmented to be diamond crystals,",
    l: "we named it as Hacash Diamond Crystals, abbreviated as hacds.",
    m: "hacash official：",
    n: "TG community：",
    o: "hacdcrystals(@Nakahacds)：",
    p: "discord：",
    q: "Learn more",
    r: "White paper",
    s: "Development tool",
    t: "Hacds",
    u: "Diamonds msg",
    v: " count",
    w: "Get MoneyNex",
    x: "Engrave hacds",
  },
};
