import { insertEmail, updateEmail, queryEmail } from "@/services/index";
import store from "@/store";
export const getEmail = async (params) => {
  const res = await queryEmail(params);
  if (res?.resultCode == 0 && res?.data?.email)
    store.commit("system/setState", { email: res.data.email });
  return res;
};
export const inEmail = async (params) => {
  const { address } = params;
  const res = await insertEmail(params);
  await getEmail({ address });
  return res;
};
export const upEmail = async (params) => {
  const { address } = params;
  const res = await updateEmail(params);
  await getEmail({ address });
  return res;
};
