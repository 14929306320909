<template>
  <div id="app" v-loading="systemLoading">
    <keep-alive :include="['index', 'sell', 'allGoods']">
      <router-view />
    </keep-alive>
    <UserEmailCom></UserEmailCom>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import UserEmailCom from "@/components/UserEmailCom";
export default {
  data() {
    return {
      timer: null,
    };
  },
  components: { UserEmailCom },
  computed: {
    ...mapGetters(["systemLoading"]),
  },
  created() {
    const timeFn = () => {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        const { innerWidth } = window;
        this.$store.commit("system/setState", { app: innerWidth < 600 });
      }, 250);
    };
    window.onresize = timeFn;
    timeFn();
  },
};
</script>
