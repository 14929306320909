import i18n from "@/public/i18n";
console.log(i18n);

export const priceOptions = [
  {
    // label: "价格",
    label: "subheading.price",
    icon: "el-icon-top",
    value: 0,
  },
  {
    label: "subheading.price",
    icon: "el-icon-bottom",
    value: 1,
  },
];

export const menuOptions = [
  {
    label: "header.home",
    path: "/home",
    icon: "wap-home-o",
  },
  {
    label: "header.sellOrder",
    path: "/index",
    icon: "goods-collect-o",
  },
  {
    label: "header.sell",
    path: "/sell",
    icon: "diamond-o",
  },
  {
    label: "header.stash",
    path: "/warehouse",
    icon: "shop-o",
  },
  {
    label: "header.mine",
    path: "/my",
    icon: "contact-o",
  },
];
export const indexBtnsOptions = [
  {
    label: "subheading.allproducts",
    value: "all",
  },
  {
    label: "subheading.popularCollection",
    value: "hot",
  },
];
export const sellBtnsOptions = [
  {
    label: "subheading.selfSale",
    value: "mySelfSell",
  },
  {
    label: "subheading.offerForPurchase",
    value: "holpBuyToOther",
  },
  {
    label: "subheading.askMeForIt",
    value: "needBuyfromMe",
  },
];
export const LanguageList = [
  {
    id: 0,
    label: "Language.Chinese",
    value: "cn",
  },
  {
    id: 1,
    label: "Language.English",
    value: "en",
  },
];
export const textBtn = [
  {
    id: 0,
    label: "subheading.filter",
    value: "筛选",
  },
  {
    id: 1,
    label: "header.search",
    value: "搜索",
  },
  {
    id: 2,
    label: "buy.buyNow",
    value: "立即购买",
  },
  {
    id: 3,
    label: "buy.collected",
    value: "已收藏",
  },
  {
    id: 4,
    label: "buy.collect",
    value: "收藏",
  },
  {
    id: 5,
    label: "placeholder.pleaseEnterContent",
    value: "请输入内容",
  },
  {
    id: 6,
    label: "header.tradingPlatform",
    value: "交易平台",
  },
  {
    id: 7,
    label: "header.tradingPlatform",
    value: "请选择",
  },
  {
    id: 8,
    label: "Language.Language",
    value: "换肤",
  },
  {
    id: 9,
    label: "placeholder.noData",
    value: "暂无数据",
  },
  {
    id: 10,
    label: "subheading.allgoods",
    value: "全部物品",
  },
  {
    id: 11,
    label: "buy.sold",
    value: "已出售",
  },
  {
    id: 12,
    label: "buy.listed",
    value: "已上架",
  },
  {
    id: 13,
    label: "buy.all",
    value: "全选",
  },
  {
    id: 14,
    label: "buy.list",
    value: "上架",
  },
  {
    id: 15,
    label: "buy.cancel",
    value: "取消",
  },
  {
    id: 16,
    label: "btn.ok",
    value: "确定",
  },
  {
    id: 17,
    label: "btn.reset",
    value: "重置",
  },
  {
    id: 18,
    label: "btn.unmerge",
    value: "解除合并",
  },
  {
    id: 19,
    label: "message.unmergeSuccessful",
    value: "解除合并成功！",
  },
  {
    id: 20,
    label: "buy.unlist",
    value: "下架",
  },
  {
    id: 21,
    label: "buy.price",
    value: "已出售",
  },
  {
    id: 22,
    label: "placeholder.pleaseEnterContentPrice",
    value: "请输入价格",
  },
  {
    id: 23,
    label: "placeholder.addDescription",
    value: "添加描述",
  },
  {
    id: 24,
    label: "placeholder.pleaseEnterPricing",
    value: "请输入定价",
  },
  {
    id: 25,
    label: "btn.neetBuy",
    value: "请输入定价",
  },
];

export const myTabsOptions = [
  {
    label: "title.myOrders",
    value: 0,
  },
  {
    label: "title.waitOrders",
    value: 1,
  },
  {
    label: "title.successOrders",
    value: 2,
  },
  {
    label: "title.errorOrders",
    value: 3,
  },
];
