import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./en";
import cn from "./cn";
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: window.localStorage.getItem("Language")
    ? window.localStorage.getItem("Language")
    : "en",
  messages: { en, cn },
});

export default i18n;
