import { queryDiamondbyCondition, queryDiamondsHacds } from "@/services";
import { Diamond } from "@/class/diamond";
class HomeDiamondClass {
  list = [];
  showList = [];
  _current = 1;
  set current(current) {
    this._current = current;
    this.changeCurrent(current);
  }
  get current() {
    return this._current;
  }
  pageSize = 20;
  isLoad = false;
  hacds = 0;
  constructor() {
    this.getList();
  }
  async getList() {
    try {
      const [res1, res2] = await Promise.all([
        queryDiamondbyCondition({
          current: 1,
          pageSize: 99999,
        }),
        queryDiamondsHacds(),
      ]);
      if (res1?.resultCode == 0 && res1?.data?.data?.length) {
        const { data } = res1.data;
        this.list = data.map((item) => new Diamond(item));
        this.changeCurrent(1);
      }
      if (res2?.resultCode == 0 && res2?.data?.hacds) {
        this.hacds = res2.data.hacds;
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoad = true;
    }
  }
  changeCurrent(current) {
    this.showList = this.list.slice(
      (current - 1) * this.pageSize,
      (current - 1) * this.pageSize + this.pageSize
    );
  }
}
export default {
  namespaced: true,
  state: new HomeDiamondClass(),
  mutations: {
    setState(state, payload) {
      for (let key in payload) {
        state[key] = payload[key];
      }
    },
  },
  actions: {},
};
