// 引入 axios
import axios from "axios";
import { getAddress } from "./handle";
import { Message } from "element-ui";
import moment from "moment";
import { KEY } from "@/keys";
import { encrypt, decrypt } from "@/utils/crypto";

const CancelToken = axios.CancelToken;
// 创建一个用于取消请求的 source
const cancelSource = CancelToken.source();
// 创建 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // 当发送跨域请求时携带cookie
  withCredentials: true, // 默认的
  // timeout: 60000, // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    config.cancelSource = cancelSource.token;
    if (config.headers.hasAddress) {
      const address = getAddress();
      if (!address)
        Message.warning({ message: "please login", showClose: true });
      config.headers["Authorization"] = address;
    }
    const b = `${moment().valueOf()}|${KEY}`;
    const a = encrypt(b);
    // console.log(decrypt(a));
    config.headers.encryption = a;
    return config;
  },
  (error) => Promise.reject(error)
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    // 如果是返回的数据不是对象则直接返回
    if (typeof res !== "object") return res;
    // 根据接口的要求，进行不同处理
    // 这里只是简单的处理，实际项目中，你可能需要更多的判断
    if (res.resultCode !== 0) {
      Message.error(res?.resultMsg ?? "Error");
      return {};
    } else return res;
  },
  (error) => {
    console.error(error);
    return {};
  }
);

export default service;
