import getList from "@/actions/getList";
import search from "../common/search";
import { queryDiamondbyCondition } from "@/services";
import { getAddress } from "@/utils/handle";

export default {
  namespaced: true,
  state: {
    list: [],
    search: search(),
    actDiamond: null,
    hasMore: true,
  },
  getters: {},
  mutations: {
    setState(state, payload) {
      for (let key in payload) {
        state[key] = payload[key];
      }
    },
  },
  actions: {
    nextPage({ state, commit }) {
      // let { search, list } = state;
      // search.current++;
      // commit("setState", { search });
      // search.address = getAddress();
      // getList(search, queryDiamondbyCondition).then((data) => {
      //   if (!data.length) commit("setState", { hasMore: false });
      //   commit("setState", { list: list.concat(data) });
      // });
    },

    firstPage({ state, commit }) {
      let { search } = state;
      search.current = 1;
      search.pageSize = 999999;
      commit("setState", { search, hasMore: false });
      search.address = getAddress();
      getList(search, queryDiamondbyCondition).then((data) => {
        commit("setState", { list: data });
      });
    },
  },
};
