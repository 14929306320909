import { Diamond } from "@/class/diamond";
import { handleGetListParams } from "@/utils/handle";

export default function getList(search, fn) {
  if (!fn) {
    return Promise.resolve([]);
  }
  const params = handleGetListParams(search);
  return fn(params).then((res) => {
    if (res?.resultCode == 0) {
      return res?.data?.data?.map((a) => new Diamond(a)) ?? [];
    }
  });
}
