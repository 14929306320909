import Vue from "vue";
import VueRouter from "vue-router";
import { getAddress } from "@/utils/handle";
import { connect } from "@/services/moneyNex";
import { initMoneyNex } from "@/actions/isOk";
import { IS_TEST } from "@/keys";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
    component: () => import("@/layout/index.vue"),
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("../views/home"),
        meta: {},
      },
      {
        path: "index",
        name: "index",
        component: () => import("../views/index"),
        meta: { hasHeader: true, hasSearch: true },
      },
      {
        path: "sell",
        name: "sell",
        component: () => import("../views/sell"),
        meta: { hasHeader: true, hasSearch: true },
      },
      {
        path: "warehouse",
        name: "warehouse",
        redirect: "warehouse/allGoods",
        component: () => import("../views/warehouse"),
        children: [
          {
            path: "allGoods",
            name: "warehouse/allGoods",
            component: () => import("../views/warehouse/allGoods"),
            meta: { hasHeader: true, hasSearch: true },
          },
          {
            path: "listing",
            name: "warehouse/listing",
            component: () => import("../views/warehouse/listing"),
            meta: { hasHeader: true, hasSearch: true },
          },
        ],
      },
      {
        path: "my",
        name: "my",
        component: () => import("../views/my"),
        meta: { hasHeader: true, hasSearch: false },
      },
    ],
  },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // if (!(await initMoneyNex())) return;
  if (IS_TEST) return next();
  const preRoute = to.name.split("/")[0];
  const filter = ["sell", "my", "warehouse"];
  if (filter.some((name) => name == preRoute)) {
    if (await initMoneyNex()) {
      if (getAddress()) next();
      else {
        if (await connect()) next();
      }
    }
  } else next();
});

export default router;
