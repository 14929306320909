import { flatMap, isEmpty } from "lodash";
import store from "@/store";
import { SPECIAL_SYMBOLS, IS_TEST } from "@/keys";
import i18n from "@/public/i18n";
const Big = require("big.js");
export const handleAttrList = (list) => {
  const types = [];
  list.forEach((item) => {
    item.metric_cn = item.metric = item.metric.split("|")[0];
    item.metric_en = item.metric_en.split("|")[0];
    const type = types.find((a) => a.type == item.type);
    if (!type) {
      types.push({
        type: item.type,
        typeEn: item.typeEn,
        list: [item],
      });
    } else {
      type.list.push(item);
    }
  });
  return types;
};

export const batchChangeChecked = (checked, list) => {
  const _list = list.filter((a) => a.status != 0 && !a.disabled);
  if (checked) _list.forEach((a) => (a.checked = false));
  else _list.forEach((a) => (a.checked = true));
};

export const batchChangeListingCheck = (checked, list) => {
  const _list = list.filter((a) => a.checked && a.status == "1");
  if (checked) _list.forEach((a) => (a.listingChecked = false));
  else _list.forEach((a) => (a.listingChecked = true));
};
export const handleGetListParams = (condition) => {
  const params = {
    current: 0,
    pageSize: 999999,
    order: 0,
    likeName: "",
    likeNumber: "",
    minPrice: "",
    maxPrice: "",
    shapes: "",
    colorClassify: "",
    letterFeature: "",
    ...condition,
  };

  if (!isEmpty(condition.search)) {
    params.likeName = condition.search;
    delete params.search;
  }
  // todo 新增了两个类型，这边需要对齐一下
  const filterArr = flatMap(store.getters.attrList?.map((a) => a.list) ?? []);
  if (filterArr.length) {
    for (let key in params) {
      if (params[key] === "") {
        delete params[key];
        continue;
      }
      try {
        const item = filterArr.find(
          (a) => a.metric_cn == params[key] || a.metric_en == params[key]
        );
        if (item) params[key] = item.metric;
      } catch (err) {
        console.log(err);
      }
    }
  }

  return params;
};

// 后续接口如果需要address，用户的id
export const getAddress = () => {
  const userAddress = store.getters.userAddress;
  return IS_TEST
    ? "1EYAe5WuDyS2qqmnqwpRLvNiJR3TGuDjz7"
    : userAddress
    ? userAddress
    : "";
};

export const email = () => {
  const email = store.getters.email;
  return email;
};

// 处理数字
function handleNum(num) {
  let res = num;
  const str = `${num}`;
  if (str.includes(".")) {
    const [zs, xs] = str.split(".");
    if (xs.length > 4) {
      const _xs = Number(xs.substring(0, 4)) + 1;
      res = `${zs}.${_xs}`;
    }
  }
  return Number(res);
}
export const handleHac = (str) => {
  str = `${str}`;
  let priceHac = false;
  let priceInt = false;
  if (str.includes(":")) {
    priceHac = str;
    const [str_price, str_p248] = str.split(":");
    const doulb = Big(10).pow(str_p248);
    priceInt = Big(str_price).div(doulb);
  } else {
    priceInt = Number(str);
    // 处理hac
    if (str.includes(".")) {
      const [_prePrice, lastPrice] = str.split(".");
      const prePrice = _prePrice == "0" ? "" : _prePrice;
      const allPrePrice = `${prePrice}${lastPrice}`;
      const lastLength = 248 - lastPrice.length;
      priceHac = `${allPrePrice}:${lastLength}`;
    } else {
      priceHac = `${str}:248`;
    }
  }

  return { priceHac, priceInt };
};
export const handleFeeHac = (price) => {
  const { priceInt } = handleHac(price);
  const p_bf10 = Big(0.1).times(priceInt);
  let res = false;
  if (p_bf10 > 1) {
    res = handleHac(1);
  } else {
    // 上架已经限制了最低不可以低过0.1个hac,这里就不用限制了
    res = handleHac(handleNum(p_bf10));
  }
  return res;
};

export const fee_ratio = (price) => {
  const { priceInt } = handleHac(price);
  const p_bf10 = Big(0.1).times(priceInt);
  let res = false;
  if (p_bf10 > 1) {
    res = handleHac(handleNum(1 / priceInt));
  } else {
    res = handleHac(0.1);
  }
  return res;
};

// 计算时间差值
export function intervalTime(startTime, endTime, type = "text") {
  const cha = endTime - startTime;
  const day = Math.floor(cha / (24 * 3600 * 1000));
  const hours = Math.floor((cha % (24 * 3600 * 1000)) / (3600 * 1000));
  const minutes = Math.floor(
    ((cha % (24 * 3600 * 1000)) % (3600 * 1000)) / (60 * 1000)
  );
  const seconds = Math.floor(
    (((cha % (24 * 3600 * 1000)) % (3600 * 1000)) % (60 * 1000)) / 1000
  );
  let s1 = "";
  if (type == "text") {
    if (day >= 1) s1 += day + i18n.t("title.dd");
    if (hours >= 1) s1 += hours + i18n.t("title.hh");
    if (minutes > 0) s1 += minutes + i18n.t("title.mm");
    if (seconds > 0) s1 += seconds + i18n.t("title.ss");
    return s1;
  } else {
    const obj = {
      day,
      hours,
      minutes,
      seconds,
    };
    return obj;
  }
}

// 限制输入数字并且最小为1

export function inputNumberBig1(value) {
  // 移除所有非数字和小数点的字符
  let numericValue = value.replace(/[^0-9.]/g, "");
  // 确保小数点只出现一次
  const onlyOneDot = numericValue.split(".").length <= 2;
  if (!onlyOneDot) {
    // 如果小数点超过一个，移除多余的小数点
    const parts = numericValue.split(".");
    numericValue = parts[0] + "." + parts[1];
  }
  // 将字符串转换为数字
  const numberValue = parseFloat(numericValue);
  // 确保输入的最小值为1
  if (numberValue < 1) {
    numericValue = "1";
  }
  value = numericValue;
}
