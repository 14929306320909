import Vue from "vue";
import Vuex from "vuex";
import system from "./modules/system";
import index from "./modules/index";
import my from "./modules/my";
import sell from "./modules/sell";
import warehouse from "./modules/warehouse";
import home from "./modules/home";

Vue.use(Vuex);

export default new Vuex.Store({
  getters: {
    // system
    actPath: (state) => state.system.actPath,
    attrList: (state) => state.system.attr.list,
    userAddress: (state) => state.system.userAddress,
    systemLoading: (state) => state.system.loading,
    systemStyleType: (state) => state.system.styleType,
    email: (state) => state.system.email,
    isApp: (state) => state.system.app,
    // index
    indexActBtn: (state) => state.index.indexActBtn,
    indexOrder: (state) => state.index.order,
    indexList: (state) => state.index.list,
    indexActDiamond: (state) => state.index.actDiamond,
    // sell
    sellActBtn: (state) => state.sell.sellActBtn,
    sellOrder: (state) => state.sell.order,
    sellList: (state) => state.sell.list,
    sellActDiamond: (state) => state.sell.actDiamond,
    // warehouse
    warehouseList: (state) => state.warehouse.list,
    // home
    homeData: (state) => state.home,
  },
  modules: { system, index, my, sell, warehouse, home },
});
