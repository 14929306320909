import store from "@/store";
import { Message } from "element-ui";
import i18n from "@/public/i18n";
import { connect } from "@/services/moneyNex";
import $bus from "@/public/$bus";
export const initMoneyNex = () => {
  let times = 0;
  return new Promise((resolve, inject) => {
    if (window.MoneyNex) return resolve(true);
    function init() {
      if (window.MoneyNex) {
        resolve(true);
      } else {
        times += 1;
        if (times < 30)
          setTimeout(() => {
            init();
          }, 30);
        else {
          Message.warning({
            message: i18n.t("message.notFindNex"),
            showClose: true,
          });
          resolve(false);
        }
      }
    }
    init();
  });
};
export const isOk = () => {
  if (!store.getters.userAddress) {
    connect();
    return false;
  }
  if (!store.getters.email) {
    Message.warning({
      message: i18n.t("message.needEnterEmail"),
      showClose: true,
    });
    $bus.$emit("email-open");
    return false;
  }
  return true;
};
