<template>
  <el-dialog
    :title="title"
    :visible.sync="show"
    width="70%"
    :show-close="false"
    destroy-on-close
    :before-close="close"
  >
    <el-form ref="emailRef" :model="form">
      <el-form-item
        prop="diffEmail"
        :rules="[
          { required: true, message: $t('placeholder.email'), trigger: 'blur' },
          {
            type: 'email',
            message: $t('error.email'),
            trigger: ['blur', 'change'],
          },
        ]"
      >
        <el-input type="email" v-model="form.diffEmail"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="commit">{{ $t("btn.ok") }}</el-button>
      <el-button @click="close">{{ $t("btn.cancel") }}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { getAddress } from "@/utils/handle";
import { getEmail } from "@/actions/email";
export default {
  data() {
    return {
      form: {
        diffEmail: "",
      },
      show: false,
    };
  },

  methods: {
    open() {
      this.form.diffEmail = this.email;
      this.show = true;
    },
    close() {
      this.show = false;
    },
    async commit() {
      this.$refs.emailRef.validate((valid) => {
        if (valid) {
          this.$store.dispatch("system/commitEmail", {
            params: {
              address: getAddress(),
              email: this.form.diffEmail,
            },
            isInsertEmail: this.isInsertEmail,
          });
          this.show = false;
        }
      });
    },
  },
  mounted() {
    if (getAddress()) getEmail({ address: getAddress() });
    this.$bus.$on("email-open", this.open);
  },
  computed: {
    ...mapGetters(["email"]),
    isInsertEmail() {
      return !this.email;
    },
    title() {
      if (this.email) {
        return this.$t("title.updateEmail");
      }
      return this.$t("title.inserEmail");
    },
  },
};
</script>
