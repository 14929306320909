import { sellBtnsOptions, priceOptions } from "@/utils/options";
import getList from "@/actions/getList";
import search from "../common/search";
import { queryDiamondbyCondition, queryOrder } from "@/services";
import { getAddress } from "@/utils/handle";
import { OrderClass } from "@/class/diamond";
import { handleGetListParams } from "@/utils/handle";

const urlMap = {
  mySelfSell: queryDiamondbyCondition,
  holpBuyToOther: queryOrder, // 求购报价的请求方法
  needBuyfromMe: queryOrder, // 求购报价的请求方法
};
export default {
  namespaced: true,
  state: {
    sellActBtn: sellBtnsOptions[0].value,
    order: priceOptions[0].value,
    list: [],
    search: search(),
    actDiamond: null,
    hasMore: true,
  },
  getters: {},
  mutations: {
    setState(state, payload) {
      for (let key in payload) {
        state[key] = payload[key];
      }
    },
  },
  actions: {
    nextPage({ state, commit }) {
      // let { search, sellActBtn, list } = state;
      // search.current++;
      // commit("setState", { search });
      // search.address = getAddress();
      // const _search = {
      //   ...search,
      // };
      // getList(search, urlMap[sellActBtn]).then((data) => {
      //   if (!data.length) commit("setState", { hasMore: false });
      //   commit("setState", { list: list.concat(data) });
      // });
    },

    async firstPage({ state, commit }) {
      let { search, sellActBtn } = state;
      search.current = 1;
      commit("setState", { search, hasMore: true, list: [] });
      const _search = {
        ...search,
        pageSize: 999999,
      };
      if (sellActBtn == "mySelfSell") {
        _search.address = getAddress();
        _search.status = 2;
        getList(_search, urlMap[sellActBtn]).then((data) => {
          commit("setState", { list: data });
        });
      } else {
        _search.status = 1;
        delete _search.address;
        if (sellActBtn == "holpBuyToOther") {
          _search.buyer = getAddress();
        } else if (sellActBtn == "needBuyfromMe") {
          _search.seller = getAddress();
        }
        const res = await queryOrder(handleGetListParams(_search));
        if (res?.resultCode == 0 && res?.data?.length) {
          const list = res.data.map((a) => new OrderClass(a));
          commit("setState", { list });
        }
      }
    },
  },
};
